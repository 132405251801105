import React from "react";
import NavBar from '../components/navbar'
import Footer from "../components/footer";
import Img1 from "./../images/slide-home-grupo-lae-empresas.jpg"
import { Link } from "react-router-dom";

const Transparencia = () => {
    return ( 
        <>
            <NavBar />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto">
                        <h1 className="text-white mb-1 text-white">TRANSPARENCIA</h1>
                        <span className='linea-header'></span>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container politicas text-left">
                    <h5>1. Proceso de Selección del Alumnado</h5>
                    <p>La selección del alumnado se lleva a cabo en base a un proceso transparente, abierto e igualitario, coordinado por el departamento de subvenciones de formación encargado de la selección que previamente informa a los interesados sobre los criterios.</p>
                    <p><u>Las vías de captación para atraer a los alumnos son las siguientes:</u></p>
                    <h6><strong>Plataformas de Empleo y Redes Sociales</strong></h6>
                    <ul>
                        <li><strong>Portales de Empleo:</strong> Publicar ofertas en sitios especializados en empleo y formación, como portales de formación de los servicios públicos de empleo.</li>
                        <li><strong>Redes Sociales:</strong> Utilizar plataformas como LinkedIn, Facebook e Instagram para llegar a un público más amplio. Publicar contenido atractivo sobre los programas disponibles.</li>
                    </ul>
                    <h6><strong>Campañas de Marketing Digital</strong></h6>
                    <ul>
                        <li><strong>Anuncios Pagados:</strong> Realizar campañas de publicidad online (Google Ads, Facebook Ads) dirigidas a grupos específicos interesados en formación.</li>
                        <li><strong>Email Marketing:</strong> Enviar newsletters a listas de contactos interesados en formación y desarrollo profesional.</li>
                    </ul>
                    <h6><strong>Programas de Orientación Laboral</strong></h6>
                    <ul>
                        <li><strong>Colaboración con Servicios de Empleo:</strong> Trabajar con servicios públicos de orientación laboral que puedan recomendar candidatos para la formación subvencionada. </li>
                        <li><strong>Redes de Antiguos Alumnos.</strong></li>
                        <li><strong>Redes de Alumnos:</strong>  Utilizar bases de datos de antiguos alumnos de programas previos para informarles sobre nuevas oportunidades.</li>
                    </ul>
                    <h6><strong>Anuncios digitales en medios especializados en distintos sectores</strong></h6>
                    <ul>
                        <li><strong>Prensa y Revistas:</strong> Una de nuestras estrategias para captar alumnos consiste en la publicación de notas de prensa en diversos medios especializados, lo que nos permite llegar a un público interesado en la formación y aumentar nuestra visibilidad en el sector. Estas publicaciones nos ayudan a destacar nuestras ofertas educativas y conectar con potenciales estudiantes de manera efectiva. </li>
                    </ul>
                    <h6><strong>Colaboraciones con Empresas</strong></h6>
                    <ul>
                        <li><p><strong>Iniciativas Empresariales:</strong> Colaborar con empresas que puedan estar interesadas en formar a sus empleados a través de programas subvencionados.</p><p> Tanto nuestra red comercial como nuestras Agentes telefónicas se encarga de visitar y/o llamar directamente a las empresas para presentar a los responsables de recursos humanos o formación los programas de formación dirigidos a empleados en activo. De este modo, las empresas pueden informar a sus trabajadores sobre las oportunidades formativas disponibles, facilitando su acceso a cursos diseñados para mejorar sus competencias y crecimiento profesional.</p></li>
                    </ul>
                    <p>Estas vías permiten ampliar el alcance y atraer a una variedad de candidatos que puedan beneficiarse de la formación subvencionada. El proceso de selección puede variar según la especialidad formativa o itinerario educativo, ya que cada uno puede tener requisitos y competencias específicas.</p>
                    <ul>
                    <p>Identificar las dificultades en la captación de candidatos para programas de formación subvencionada es crucial para mejorar el proceso.</p>
                    <p><u>Las dificultades encontradas para la captación son la siguientes:</u></p>
                    <h6><strong>1. Falta de Candidatos</strong></h6>
                    <ul>
                        <li><strong>Poca Conciencia del Programa:</strong> Muchos potenciales candidatos pueden no conocer la existencia de programas subvencionados, esto se da en mayor grado en los candidatos ocupados. </li>
                        <li><strong>Mercado Laboral Competitivo:</strong> En áreas con alta demanda de empleo, los candidatos pueden optar por trabajar en lugar de participar en programas de formación.</li>
                        <li><strong>Ubicación Geográfica:</strong> En algunas regiones, la falta de acceso a la formación o a recursos educativos puede limitar la cantidad de candidatos interesados.</li> 
                    </ul>
                    <h6><strong>2. Falta de Interés en las acciones formativas</strong></h6>
                    <ul>
                        <li><strong>Percepción Negativa de ciertas acciones:</strong> Algunos cursos pueden tener una imagen menos atractiva, lo que desmotiva a los candidatos a formarse en ellas. </li>
                        <li><strong>Bajos Salarios Ofrecidos en el Sector:</strong> Si los salarios en ciertos campos son percibidos como bajos, esto puede desalentar a las personas de querer formarse en esos sectores.</li>
                    </ul>
                    <h6><strong>3. Requisitos y Barreras Administrativas</strong></h6>
                    <ul>
                        <li><strong>Complicaciones en el Proceso de Inscripción:</strong> Un proceso de inscripción complejo o burocrático puede disuadir a los candidatos.</li>
                        <li><strong>Requisitos Académicos Elevados:</strong> Si los requisitos de entrada son percibidos como demasiado estrictos, puede reducirse el número de candidatos.</li>
                    </ul>
                    <h6><strong>4. Falta de Motivación y Conciencia de Oportunidades</strong></h6>
                    <ul>
                        <li><strong>Desconocimiento de Beneficios y falta de tiempo:</strong> Algunos potenciales candidatos pueden no entender cómo la formación puede mejorar sus oportunidades laborales. De ello deriva que muchos no deseen invertir su tiempo de ocio en formarse. </li>
                        <li><strong>Desinterés en la Formación Continua:</strong> Puede haber una cultura de falta de interés en la capacitación continua o el desarrollo profesional una vez se consigue un empleo fijo.</li>
                    </ul>
                    <h6><strong>5. Condiciones Económicas</strong></h6>
                    <ul>
                        <li><strong>Candidatos desempleados:</strong> Las personas suelen priorizar el trabajo inmediato sobre la capacitación, por lo que una persona desempleada está más dispuesta a invertir su tiempo en formación que una ocupada.</li>
                    </ul>
                    <h6><strong>6. Problemas de Comunicación</strong></h6>
                    <ul>
                        <li><strong>Falta de Estrategias de Marketing Efectivas:</strong> Si las campañas de promoción no son claras o atractivas, los potenciales candidatos pueden no sentirse motivados a matricularse en un curso.</li>
                    </ul>
                    </ul>
                    <p><u>En programas de formación subvencionada, es común priorizar ciertos colectivos que pueden beneficiarse significativamente de la capacitación.</u></p>
                    <p>Los colectivos más habituales en general en formación subvencionada son:</p>
                    <ul>
                        <li><strong>Las mujeres:</strong> A menudo buscan formación para avanzar en sus carreras o para reinsertarse en el mercado laboral después de periodos de inactividad, como la crianza de hijos. También pueden estar más inclinadas a formarse en áreas que les interesan personalmente, como artes o idiomas.</li>
                        <li><strong>Los trabajadores de pequeñas y medianas empresas (PYMES):</strong> En general, los trabajadores de PYMES tienden a estar interesados en la formación, especialmente si perciben que aporta valor a su desarrollo profesional y a la empresa.</li>
                        <li><strong>Desempleados:</strong> Los desempleados buscan mejorar sus habilidades y competencias para aumentar sus posibilidades de encontrar trabajo, y la formación subvencionada ofrece una forma accesible de hacerlo ya que, además, no supone un coste adicional.</li>
                        </ul>
                    <p>Estos colectivos generalmente disponen de estudios acreditados ya sean básicos o superiores y experiencia profesional acreditada, por ello, no suele haber mayor inconveniente en que participen en acciones formativas en las que se pide unos requisitos mínimos.</p>
                    <p><u>Propuestas de mejora para captación</u></p> 
                    <ul>
                        <li><strong>Promoción Activa:</strong> Realizar campañas informativas que expliquen los beneficios de la formación subvencionada, utilizando testimonios de ex-alumnos y estadísticas sobre la mejora en la empleabilidad.</li>
                        <li><strong>Colaboración con Entidades Locales:</strong> Trabajar con asociaciones de desempleados y servicios sociales para llegar a colectivos que pueden beneficiarse de la formación.</li>
                        <li><strong>Análisis del Mercado Laboral:</strong> Realizar estudios sobre las competencias más demandadas en el mercado laboral local y ajustar la oferta formativa en consecuencia. </li>
                        <li><strong>Mentoría y Asesoría:</strong> Ofrecer asesoramiento individualizado para ayudar a los interesados a identificar qué tipo de formación se ajusta mejor a sus necesidades y aspiraciones.</li>
                        <li><strong>Datos de Éxito:</strong> Publicar estadísticas sobre la tasa de empleabilidad de los graduados de programas anteriores para demostrar la efectividad de la formación.</li> 
                    </ul>  
                    <h5>2. Perfil del Personal Formador</h5>
                    <p>El perfil del formador en programas de formación subvencionada es crucial para asegurar la calidad y efectividad de la formación.</p>
                    <h6><strong>1. Formación Académica y Experiencia</strong></h6> 
                    <ul>
                        <li><strong>Titulación Adecuada:</strong> Debe contar con una formación académica acorde con el  área que va a impartir. Esto puede incluir títulos universitarios, certificaciones profesionales o diplomas en pedagogía.</li>
                        <li><strong>Experiencia Profesional:</strong> Es importante que el formador tenga experiencia práctica en el sector correspondiente, lo que le permitirá ofrecer ejemplos reales y aplicados a los participantes.</li>
                    </ul>
                    <h6><strong>2. Habilidades Pedagógicas</strong></h6>
                    <ul>
                        <li><strong>Metodología de Enseñanza:</strong> Debe dominar diferentes métodos y técnicas de enseñanza, adaptándose a las necesidades y estilos de aprendizaje de los participantes.</li>
                        <li><strong>Diseño de Programas:</strong> Capacidad para diseñar programas de formación que sean estructurados, claros y adaptados al perfil del alumnado.</li>
                    </ul>
                    <h6><strong>3. Competencias Interpersonales</strong></h6>
                    <ul>
                        <li><strong>Comunicación Efectiva:</strong> Habilidad para comunicar conceptos complejos de manera clara y comprensible, utilizando un lenguaje accesible.</li>
                        <li><strong>Empatía y Escucha Activa:</strong> Capacidad para entender las necesidades y preocupaciones de los participantes, fomentando un ambiente de confianza y colaboración.</li>
                    </ul>
                    <h6><strong>4. Actualización y Adaptación</strong></h6>
                    <ul>
                        <li><strong>Formación Continua:</strong> Compromiso con la formación continua y la actualización de conocimientos en su área de especialización y en técnicas pedagógicas.</li>
                        <li><strong>Adaptabilidad:</strong> Capacidad para ajustar su enfoque de enseñanza según el contexto, los cambios en el mercado laboral y las demandas específicas de los participantes.</li>
                    </ul>
                    <h6><strong>5. Orientación a Resultados</strong></h6>
                    <ul>
                        <li><strong>Evaluación de Aprendizajes:</strong> Habilidad para evaluar y medir el progreso de los participantes, utilizando diferentes herramientas de evaluación.</li>
                    </ul>
                    <h6><strong>6. Uso de Tecnologías</strong></h6>
                    <ul>
                        <li><strong>Competencia Digital:</strong> Familiaridad con herramientas tecnológicas que faciliten la enseñanza, especialmente en formatos online o híbridos, como plataformas de e-learning y software educativo.</li>
                        <li><strong>Innovación en Métodos de Enseñanza:</strong> Capacidad para integrar nuevas tecnologías y métodos innovadores en sus sesiones formativas.</li>
                    </ul>
                    <h6><strong>7. Capacidad de Motivación</strong></h6>
                    <ul>
                        <li><strong>Inspirar y Motivar:</strong> Habilidad para motivar a los participantes, creando un ambiente de aprendizaje dinámico y participativo que fomente el interés y la curiosidad.</li>
                    </ul>
                    <h6><strong>8. Ética y Responsabilidad</strong></h6>
                    <ul>
                        <li><strong>Compromiso Ético:</strong> Debe tener un fuerte sentido de ética profesional, mostrando respeto y responsabilidad hacia los participantes, así como confidencialidad en la gestión de información personal.</li>
                    </ul>
                    <h5>3. Ejecución del programa formativo</h5>
                    <p>1. Cronología del Programa (Modalidad teleformación)</p>
                    <ul>
                        <p>Fase 1: Preparación (4 semanas antes del inicio)</p>
                        <ul>
                            <li><strong>Selección de Formadores:</strong> Identificación y contratación de formadores con el perfil adecuado.</li>
                            <li><strong>Difusión y Captación:</strong> Promoción del programa y registro de participantes.</li>
                        </ul>
                        <p>Fase 2: Inicio del Programa (Día de inicio de curso)</p>
                        <ul>
                            <li><strong>Mensaje de Bienvenida:</strong> Presentación del curso, objetivos y dinámica de trabajo.</li>
                            <li><strong>Evaluación Inicial:</strong> Prueba para conocer el nivel de conocimientos de los participantes.</li>
                        </ul>
                        <p>Fase 3: Desarrollo del Programa (3-4 semanas)</p>
                        <ul>
                            <li><strong>Sesiones de Formación:</strong> Realización de módulos formativos, que pueden ser semanales o bi-semanales.</li>
                            <li><strong>Dinamización:</strong> Realización de foros y chats en los que los alumnos están en contacto con el tutor y pueden preguntar sus dudas.</li>
                            <li><strong>Evaluaciones Continuas:</strong> Feedback constante a través de cuestionarios o actividades prácticas.</li>
                        </ul>
                        <p>Fase 4: Cierre (Última semana)</p>
                        <ul>
                            <li><strong>Evaluación Final:</strong> Pruebas o proyectos que demuestren lo aprendido.</li>
                        </ul>
                        <p>Fase 5: Encuestas de valoración (Tras la finalización)</p>
                        <ul>
                            <li>Recogida de opiniones de los participantes sobre el programa.</li>
                        </ul>
                    </ul>
                    <p>Durante el programa de formación habitualmente se dan bajas de alumnos, a continuación se destacan las causas más comunes: </p>
                    <ul>
                        <p>1. Falta de Compromiso</p>
                        <ul>
                            <li><strong>Intereses Divergentes:</strong> Algunos participantes pueden inscribirse sin un verdadero interés en la formación o en el área de estudio.</li>
                            <li><strong>Desmotivación:</strong> Pueden perder interés si no ven relevancia en el contenido para su desarrollo profesional.</li>
                        </ul>
                        <p>2. Compromisos Laborales y Personales</p>
                        <ul>
                            <li><strong>Carga Laboral:</strong> La falta de tiempo debido a responsabilidades laborales puede dificultar la asistencia regular.</li>
                            <li><strong>Responsabilidades Familiares:</strong> Las obligaciones familiares pueden interferir con la disponibilidad para participar en el programa.</li>
                        </ul>
                        <p>3. Salud y Bienestar</p>
                        <ul>
                            <li><strong>Estrés y Agotamiento:</strong> La presión personal o laboral puede afectar la capacidad de los participantes para asistir.</li>
                        </ul>
                        <p>4. Cambios en la Situación Laboral</p>
                        <ul>
                            <li><strong>Desempleo:</strong> Cambios en el estatus laboral, como despidos, pueden llevar a la baja en la formación.</li>
                        </ul>
                    </ul>
                    <p><strong>Impacto de las Bajas</strong></p>
                    <ul>
                        <li><strong>Costes:</strong> Las bajas pueden implicar una pérdida de recursos invertidos en la formación, tanto en términos de tiempo como de dinero.</li>
                        <li><strong>Efecto en la Reputación</strong> Un alto índice de bajas puede influir negativamente en la percepción del programa y su capacidad para atraer a futuros participantes.</li>
                    </ul>
                    <p><strong>Estrategias para Mitigar Bajas</strong></p>
                    <ul>
                        <p>1. Evaluación de Intereses Previos</p>
                        <ul>
                            <li><strong>Encuestas Iniciales:</strong> Realizar encuestas antes del inicio para entender las expectativas y motivaciones de los participantes.</li>
                        </ul>
                        <p>2. Soporte Continuo</p>
                        <ul>
                            <li><strong>Mentoría y Asesoría:</strong> Proporcionar apoyo individual a los participantes para abordar cualquier inquietud o dificultad que surja.</li>
                        </ul>
                        <p>3. Comunicación Clara</p>
                        <ul>
                            <li><strong>Feedback Regular:</strong> Mantener un canal de comunicación abierto para recibir y dar retroalimentación durante el proceso formativo.</li>
                        </ul>
                    </ul>
                    <p>Además, la evaluación de los estudiantes en programas de formación subvencionada es fundamental para medir su progreso y la efectividad del programa.</p>
                    <p><strong>Resultados de aprendizaje</strong></p>
                    <ul>
                        <p>Conocimientos Adquiridos</p>
                        <li><strong>Evaluaciones Escritas:</strong> Resultados de pruebas y exámenes que miden el dominio de conceptos teóricos y conocimientos específicos.</li>
                        <li><strong>Autoevaluaciones:</strong> Encuestas que permiten a los participantes reflexionar sobre su propio aprendizaje y autoconocimiento de las habilidades adquiridas.</li>
                    </ul>
                    <h5>4. Resultados de la formación</h5>
                    <p>Nuestros cursos de formación cuentan con un seguimiento personalizado por parte de tutores expertos. Los tutores realizan llamadas de seguimiento y envían mensajes para asegurarse de que los alumnos avanzan correctamente, aclarando dudas y revisando los test de evaluación por si hubiera puntos a aclarar. Además, fomentan la participación activa mediante publicaciones en el foro del curso, planteando debates y temas relacionados con el contenido para enriquecer el aprendizaje. Este acompañamiento continuo garantiza una experiencia formativa completa y eficaz.</p>
                    <h5>5. Control de Calidad</h5>
                    <p>Los sistemas internos de calidad en programas de formación subvencionada son fundamentales para asegurar que se cumplen los estándares y se satisfacen las necesidades de los alumnos y formadores.</p>
                    <p><strong>1. Encuestas de Satisfacción</strong></p>
                    <ul>
                        <li><strong>Objetivo:</strong> Recoger feedback sobre la calidad de la formación, el contenido del curso, la metodología y la actuación del formador.</li>
                        <li><strong>Diseño:</strong> Las encuestas incluyen preguntas tipo test con una escala del 1 al 4, el 1 es la puntuación que corresponde a la menor satisfacción y el 4 corresponde a la mayor satisfacción. También disponen de un campo libre para poder comentar lo que se desee.</li>
                        <li><strong>Frecuencia:</strong> Se realizan al finalizar cada curso.</li>
                        <li><strong>Público objetivo:</strong> Alumnos que han participado en la formación y formadores que han impartido los cursos.</li>
                    </ul>
                    <p><strong>2. Análisis de Resultados</strong></p>
                    <ul>
                        <li><strong>Compilación de Datos:</strong> Los resultados se recopilan y analizan utilizando herramientas estadísticas.</li>
                        <li><strong>Identificación de Áreas de Mejora:</strong> Se examinan las calificaciones bajas y los comentarios de las encuestas para identificar patrones y áreas críticas.</li>
                    </ul>
                    <h5>6. Propuestas de Mejora</h5>
                    <p><strong>Plan de Acción:</strong> Basándose en los resultados de las encuestas, se elabora un plan de acción que prioriza las áreas a mejorar.</p>
                    <ul>
                        <li><strong>Capacitación de Formadores:</strong> Si se identifica que los formadores necesitan mejorar ciertas habilidades, se organizan formaciones específicas.</li>
                        <li><strong>Revisión de Contenidos:</strong> Si el contenido de un curso no cumple con las expectativas, se revisa y actualiza para asegurar que esté alineado con las necesidades del mercado y de los alumnos.</li>
                        <li><strong>Informes de Progreso:</strong> Se generan informes que documentan las acciones tomadas y sus resultados, y se comparten con todas las partes interesadas.</li>
                    </ul>
                    <h5>7. Buenas Prácticas</h5>
                    <p>En GRUPO LAE nos comprometemos a incorporar metodologías didácticas innovadoras y a aprovechar las nuevas tecnologías para mejorar la experiencia formativa de nuestros alumnos. Promovemos el uso de herramientas tecnológicas avanzadas, como plataformas interactivas y recursos digitales, que facilitan el aprendizaje y la participación. Además, diseñamos proyectos formativos que buscan tener un impacto significativo en el desarrollo de competencias, asegurando que nuestros cursos no solo transmitan conocimientos teóricos, sino que también ofrezcan experiencias prácticas y aplicables.</p>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 225px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                `}
            </style>
        </>
     );
}
 
export default Transparencia;